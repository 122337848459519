<template>
  <logo />
  <nav>
    <router-link to="/registration">Регистрация</router-link>
    <router-link to="/list">Список зарегистрировавшихся</router-link>
    <router-link to="/schedule">Расписание</router-link>
    <router-link to="/documents">Положение</router-link>
    <router-link to="/results">Результаты</router-link>
  </nav>
  <router-view/>
</template>

<style>
:root{
  --padding-normal: 20px;
  --vertical-spacing-normal: 20px;
  --horizontal-spacing-normal: 20px;
  --vertical-spacing-small: 10px;
  --horizontal-spacing-small: 10px;
  --font-size-forms: 16px;
  --border-radius-forms: 15px;
  --input-bg-color: #f3f6ff;
  --accent-color: #00b6e8;
  /* --accent-color: #3561fe; */
  --accent-background-color: #eceffe;
  --outline-color: #d4e4fd;
  --gray-color: #7f7f7f;
  --unselected-input-outline-color: #d0dafe;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-image: url("./assets/transparent-star-background-23.png");
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center; 
  background-color: rgba(255, 255, 255, 0.5);
  height: 100vh;
  padding: 0 calc(20px - (100vw - 100%)) 0 0;
}

nav {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

nav a {
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
  color: #011a5d;
  transition: 0.5s ease-in-out;
  border: 2px solid var(--unselected-input-outline-color);
  padding: 10px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.75);
}

nav a:hover {
  transform: translateY(-5px);
  text-shadow: 0px 0px 20px #00b6e8;
  box-shadow: 0px 0px 20px #00b6e8;
  transition: 0.5s ease-in-out;
}

nav a:active {
  transform: translateY(0px);
  transition: 0.1s ease-in-out;
}

nav a.router-link-exact-active {
  color: #00b6e8;
  border: 2px solid #00b6e8;
}

@media screen and (max-width: 900px) {
  nav {
    flex-direction: column;
    max-width: 540px;
    gap: 5px;
  }

  nav a {
    font-size: 16px;
  }

  nav a:active, nav a:hover {
    transform: none;
  }
  #app {
    padding: 0 calc(20px - (100vw - 100%)) 0 20px;
  }
}

@media screen and (max-width: 584px) {
    nav {
      padding: 20px 0px;
      max-width: 500px;
    }
}

</style>

<script>
import Logo from '@/components/Logo.vue';

export default {
  name: 'App',
  components: {
    Logo
  }
}
</script>
