import { createRouter, createWebHistory } from 'vue-router'
import ListView from '../views/ListView.vue'
import RegistrationtView from '../views/RegistrationView.vue'
import SheduleView from '@/views/SheduleView.vue'
import DocumentsView from '@/views/DocumentsView.vue'
import ResultsView from '@/views/ResultsView.vue'

const routes = [
  { 
    path: '/', 
    redirect: '/registration' 
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationtView,
    alias: '/registration',
  },
  {
    path: '/list',
    name: 'list',
    component: ListView
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: SheduleView
  },
  {
    path: '/documents',
    name: 'documents',
    component: DocumentsView
  },
  {
    path: '/results',
    name: 'results',
    component: ResultsView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
