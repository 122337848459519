<template>
    <div class="timerContainer">
        <h3>До конца регистрации осталось <br><span class="time">{{ timeLeft }}</span></h3>
        <p class="subtext">29 ноября 23:59</p>
    </div>
</template>

<style scoped>
    h3 {
      font-size: 24px;
    }
    .timerContainer {
        padding: 20px 10px;
        color: #011a5d;
        position: relative;
    }

    .time {
      font-size: 24px;
      color:#00b6e8 ;
    }

    .subtext {
      font-size: 14px;
      position: absolute;
      left: calc(50% - 50px);
      bottom: 0px;
      color: rgba(0, 0, 0, 0.5);
    }

    @media screen and (max-width: 400px) {
        h3 {
          font-size: 16px;
        }
        .time {
          font-size: 20px;
        }
    }

    @media screen and (max-width: 900px) {
      .timerContainer {
          padding-top: 0px;
        }
    }
</style>

<script>
import { ref, onMounted } from 'vue';
import { DATE_OF_END } from '@/notes';

const formatNumbers = num => (num < 10 ? '0' + num : String(num))

function changeTime(DATE_OF_END, CURRENT_DATE) {
  const dateDivis = new Date(DATE_OF_END - CURRENT_DATE)

  if (DATE_OF_END < CURRENT_DATE) {
    return "00:00:00"
  }

  const day = dateDivis.getUTCDate()
  const hours = dateDivis.getUTCHours()
  const minuts = dateDivis.getUTCMinutes()
  const seconds = dateDivis.getUTCSeconds()
  
  return `${day - 1 ? day - 1 + ' дн. ' : ''}${formatNumbers(hours)}:${formatNumbers(minuts)}:${formatNumbers(seconds)}`
}

export default {
  name: 'Timer',
  data() {
    return  {
      timeLeft: 0,
      intervalId: "",
      startTimer () {
        this.intervalId = setInterval(() => {
          this.timeLeft = changeTime(DATE_OF_END, new Date())
        }, 1000);
      }, 
    }
  },
  mounted () {
    this.timeLeft = changeTime(DATE_OF_END, new Date())
    this.startTimer()
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  }
}  

export {formatNumbers, changeTime}
</script>
