<template>
  <div class="container">
    <div v-if="!loading">
      <div v-if="!error">
        <div class="filter__container">
          <p class="label">Показать: </p>
          <select class="filter" v-model="selectedCategory">
            <option value="all">Все категории</option>
            <option value="debut-solo">Дебют Соло</option>
            <option value="debut-couples">Дебют Пары</option>
            <option value="hobby-solo">Хобби Соло</option>
            <option value="hobby-couples">Хобби Пары</option>
            <option value="e-st">E класс Стандарт</option>
            <option value="d-st">D класс Стандарт</option>
            <option value="cb-st">C+B класс Стандарт</option>
            <option value="open-st">Открытый класс Стандарт</option>
            <option value="e-la">E класс Латина</option>
            <option value="d-la">D класс Латина</option>
            <option value="cb-la">C+B класс Латина</option>
            <option value="open-la">Открытый класс Латина</option>
          </select>
        </div>
        <div v-for="currentCategory in (selectedCategory === 'all' ? allCategory : [selectedCategory])" class="filteredCategoryContainer">
          <p class="count">{{ categoryName[currentCategory] }} - {{ getRegistrations(currentCategory) }}</p>
          <Registered :item="tableHeader" />
          <Registered 
            v-for="item in items.data.filter((item) => {return item.categories.includes(currentCategory)})" 
            :key="item.id"
            :item="item"
          />
        </div>
      </div>
      <h2 v-else>Сервер временно недоступен(</h2>
    </div>
    <h2 v-else>Загрузка...</h2>
  </div>
</template>

<style scoped>
  .container {
    max-width: 750px;
    margin: auto;
    background-color: white;
    box-shadow: 0px 0px 10px 10px white;
    padding-bottom: 20px;
  }

  .filter__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px
  }

  .label {
    font-size: 24px;
    font-weight: bold;
  }

  .filter {
    border: 1px solid #011a5d;
    width: 60%;
    padding: 10px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
  }

  .filteredCategoryContainer {
    margin-bottom: 30px;
  }

  .count {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
  }

@media screen and (max-width: 900px) {
  .container {
    max-width: 500px;
  }
}

@media screen and (max-width: 400px) {
  h2 {
    font-size: 20px;
  }
}
</style>

<script>
import Registered from '@/components/Registered.vue';
import { getParticipant } from '@/utils';

function getCorrectWord(count) {
  let word = "заявок"
  if ((count > 20 && count % 10 === 1) || count === 1) {
    word = "заявка"
  } else if ((count > 20 && count % 10 <= 4) || (count <= 4 && count > 0)) {
    word = "заявки"
  }
  return count + " " + word
}

export default {
  name: 'List',
  components: {
    Registered
  },
  data() {
    return {
      tableHeader:  { leader: 'ФИО пары', club: 'Университет', teacher: 'Тренер'},
      selectedCategory: "all",
      items: [],
      loading: true,
      error: null,
      allCategory: ["debut-solo", "debut-couples", "hobby-solo", "hobby-couples", "e-st", "d-st", "cb-st", "open-st", "e-la", "d-la", "cb-la", "open-la"],
      categoryName: {
        "debut-solo": "Дебют Соло",
        "debut-couples": "Дебют Пары",
        "hobby-solo": "Хобби Соло",
        "hobby-couples": "Хобби Пары",
        "e-st": "E класс Стандарт",
        "d-st": "D класс Стандарт",
        "cb-st": "C+B класс Стандарт",
        "open-st": "Открытый класс Стандарт",
        "e-la": "E класс Латина",
        "d-la": "D класс Латина",
        "cb-la": "C+B класс Латина",
        "open-la": "Открытый класс Латина"
      },
      getRegistrations:(currentCategory) => {
        if (this.items.data) {
          const count = this.items.data.filter((item) => {return item.categories.includes(currentCategory)}).length
          return getCorrectWord(count)
        }
      }
    }
  },
  async mounted() {
    try {
      const result = await getParticipant()
      this.items = result;  
    } catch (err) {
      this.error = err.message;  
      console.log(err.message)
    } finally {
      this.loading = false;  
    }
  }
}

export { getCorrectWord }

</script>