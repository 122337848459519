<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  name: 'Message',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h1 {
  padding: 20px;
  color: #011a5d;
  font-size: 32px;
}

@media screen and (max-width: 400px) {
  h1 {
    font-size: 20px; 
  }
}
</style>
