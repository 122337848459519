import { createStore } from 'vuex'

export default createStore({
  state() {
    return {
      isFinish: false, // Здесь объявляем переменную, которую хотим передавать
    };
  },
  getters: {
    getIsFinish: (state) => state.isFinish,
  },
  mutations: {
    setIsFinish(state, value) {
      state.isFinish = value;
    },
  },
  actions: {
    updateIsFinish({ commit }, value) {
      commit('updateIsFinish', value);
    },
  },
  modules: {
  }
})
