// const url = "https://kb-reg.test.ablearthy.dev/api/participants"
// const configUrl = "https://kb-reg.test.ablearthy.dev/api/config/"
const url = "/api/participants"
const configUrl = "/api/config/"

export async function createParticipant(data) {
    return fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(data)}
    ).then((res) => res)
    .catch((err) => err)
}

export async function getParticipant() {
    const response = await fetch(url)
    return response.json()
}

export async function getConfig() {
    const response = await fetch(configUrl)
    return response.json()
}