<template>
    <div class="container">
        <Message msg="Совсем скоро тут что-то появится :3"/>
    </div>
</template>

<script>

import Message from '@/components/Message.vue';

export default {
  name: 'Results',
  components: {
    Message
  }
}

</script>