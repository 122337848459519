<template>
  <div v-if="isFinish" class="container">
    <h1>К сожалению регистрация подошла к концу!</h1>
  </div>
  <div v-else :class="'container' + (isValid ? '' : ' isRequire')">

    <div :class="'categories' + (this.selectedCategorySt || this.selectedCategoryLa || this.selectedCategory ? '' : ' require')">
      <h2>Выберите категорию</h2>
      <CategoryButton :isLong='true' name='debut-couples' fullName='Дебют Пары' :changeCategory='changeCategory' :selectedCategory='selectedCategory'/>
      <CategoryButton :isLong='true' name='debut-solo' fullName='Дебют Cоло' :changeCategory='changeCategory' :selectedCategory='selectedCategory'/>
      <CategoryButton :isLong='true' name='hobby-couples' fullName='Хобби Пары' :changeCategory='changeCategory' :selectedCategory='selectedCategory'/>
      <CategoryButton :isLong='true' name='hobby-solo' fullName='Хобби Соло' :changeCategory='changeCategory' :selectedCategory='selectedCategory'/>
      <div class="st_la_container">
        <div class="standart">
          <CategoryButton :isLong='false' name='e-st' fullName='Стандарт E' :changeCategory='changeCategory' :selectedCategory='selectedCategorySt'/>
          <CategoryButton :isLong='false' name='d-st' fullName='Стандарт D' :changeCategory='changeCategory' :selectedCategory='selectedCategorySt'/>
          <CategoryButton :isLong='false' name='cb-st' fullName='Стандарт C+B' :changeCategory='changeCategory' :selectedCategory='selectedCategorySt'/>
          <CategoryButton :isLong='false' name='open-st' fullName='Стандарт Открытый' :changeCategory='changeCategory' :selectedCategory='selectedCategorySt'/>
        </div>
        <div class="latina">
          <CategoryButton :isLong='false' name='e-la' fullName='Латина E' :changeCategory='changeCategory' :selectedCategory='selectedCategoryLa'/>
          <CategoryButton :isLong='false' name='d-la' fullName='Латина D' :changeCategory='changeCategory' :selectedCategory='selectedCategoryLa'/>
          <CategoryButton :isLong='false' name='cb-la' fullName='Латина C+B' :changeCategory='changeCategory' :selectedCategory='selectedCategoryLa'/>
          <CategoryButton :isLong='false' name='open-la' fullName='Латина Открытый' :changeCategory='changeCategory' :selectedCategory='selectedCategoryLa'/>
        </div>
      </div>
    </div>

    <div ref="baseFormElement" :class="'names labelContainer' + (!this.leader1 || !this.leader2 ? ' require' : '')">
      <p class="inputLabel">{{ ((selectedCategory === 'debut-solo' || selectedCategory === 'hobby-solo') ? "Солист" : "Партнёр") }}</p>
      <Input placeholder="Фамилия" v-model="leader1"/>
      <Input placeholder="Имя" v-model="leader2"/>
    </div>

    <div :class="'names labelContainer' + (isCategoryTarget ? (!(selectedCategory === 'debut-solo' || selectedCategory === 'hobby-solo') ? ' containerOpen' + (!this.follower1 || !this.follower2 ? ' require' : '') : ' containerClose') : (!this.follower1 || !this.follower2 ? ' require' : ''))">
      <p class="inputLabel">Партнёрша</p>
      <Input placeholder="Фамилия" v-model="follower1"/>
      <Input placeholder="Имя" v-model="follower2"/>
    </div>

    <div :class="'clubContainer'">
      <div :class="'labelContainer' + (club1 === '=='  ? ' require' : '')">
        <p class="inputLabel">Учебное заведение {{((selectedCategory === 'debut-solo' || selectedCategory === 'hobby-solo') ? "солиста" : "партнёра")}}</p>
        <select :class="'club input' + (club1 === '=='  ? ' gray' : '')" v-model="club1">
          <option value="==" selected>Учебное заведение / Клуб</option>
          <option v-for="item in clubs" :value="item" :key="item">{{ item }}</option>
          <option value="Другое">Другое</option>
        </select>
      </div>
      <Input :class="(otherClub1 ? '' : 'require') + (otherClub1Triget ? (club1 === 'Другое' ? ' otherClubOpened' : ' otherClubClosed') : ' otherClub')" placeholder="Учебное заведение / Клуб" isFull v-model="otherClub1"/>
    </div>

    <div :class="'clubContainer' + (isCategoryTarget ? (!(selectedCategory === 'debut-solo' || selectedCategory === 'hobby-solo') ? ' containerOpen' : ' containerClose') : '')">
      <div class="labelContainer">
        <p class="inputLabel">Учебное заведение партнёрши</p>
        <select :class="'club input' + (club2 === '=='  ? ' gray' : '')" v-model="club2">
          <option value="==" selected>Учебное заведение / Клуб</option>
          <option v-for="item in clubs" :value="item" :key="item">{{ item }}</option>
          <option value="Другое">Другое</option>
        </select>
      </div>
      <Input :class="(otherClub2Triget ? (club2 === 'Другое' ? ' otherClubOpened' : ' otherClubClosed') : ' otherClub')" placeholder="Учебное заведение / Клуб" isFull v-model="otherClub2"/>
    </div>

    <Input :class="(result.teacher1 ? '' : 'require')" placeholder="Тренер 1" isFull v-model="result.teacher1"/>
    
    <Input placeholder="Тренер 2 (Необязательно)" isFull v-model="result.teacher2"/>
    
    <div :class="(result.contacts ? '' : 'require') + ' labelContainer'">
      <p v-if="(selectedCategory === 'debut-solo' || selectedCategory === 'hobby-solo')" class="inputLabel">Ваши контакты</p>
      <p v-else class="inputLabel">Контакт представителя пары</p>
      <Input placeholder="https://vk.com/" isFull v-model="result.contacts"/>
    </div>

    <h2 v-if="note">Важно!!!</h2>
    <p class="note" v-if="note">{{ note }}</p>
    <div class="persData">
      <input type="checkbox" name="persData" id="persData" v-model="checked"/>
      <label for="persData">Согласен на обработку персональных данных</label>
    </div>
    <!-- <Input placeholder="Номер телефона" isFull/> -->
     <p v-if="serverDown" class="message">Сервер временно недоступен, попробуйте зарегистрироваться позже :(</p>
     <p v-if="correctRegistration" class="message good">Вы успешно зарегистрировались!</p>
     <p v-if="!isValid" class="message">Заполните все обязательные поля!</p>
    <button :class="'button' + (checked ? '' : ' disabled')" @click="send" :disabled="!checked">Зарегистрировать</button>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--vertical-spacing-normal);
  transition: 1s ease;
  max-width: 500px;
  margin: auto;
  padding-bottom: 20px;
}

.isRequire .require {
  box-shadow: 0px 0px 5px 0px red;
  border-radius: var(--border-radius-forms);
}

.names {
  display: flex;
  justify-content: space-between;
  gap: var(--horizontal-spacing-normal);
}

.labelContainer {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.clubContainer {
  width: 100%;
  transition: 0.5s;
}

.clubContainer > .otherClubOpened:not(:first-child) {
  margin-top: var(--vertical-spacing-normal);
}

.inputLabel {
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  top: -18px;
  left: 0px;
  color: rgba(0, 0, 0, 0.5)
}


.clubContainer.containerOpen {
  margin-top: 0;
}
.clubContainer.containerClose {
  margin-top: calc(-1 * var(--vertical-spacing-normal));
}
.clubContainer.containerOpen .labelContainer {
  animation: names-open 1s ease;
  opacity: 1;
  margin-top: 10px;
}

.clubContainer.containerClose .labelContainer{
  animation: names-closed 1s ease;
  height: 0px;
  opacity: 0;
  z-index: -1;
  margin-top: calc(-1 * var(--vertical-spacing-normal));
}


/* .clubContainer.containerOpen .InpContainer:not(.otherClubClosed) {
  animation: common-open 1s ease;
  opacity: 1;
} */

.clubContainer.containerClose .InpContainer{
  animation: common-closed 1s ease;
  height: 0px;
  opacity: 0;
  z-index: -1;
  margin-top: calc(-1 * var(--vertical-spacing-normal));
}

.otherClub {
  height: 0px;
  opacity: 0;
}

.otherClubOpened {
  animation: other-club-open 1s ease !important;
  opacity: 1;
}

.otherClubClosed {
  animation: other-club-closed 1s ease;
  height: 0px;
  opacity: 0;
  z-index: -1;
}

.names.containerOpen {
  animation: names-open 1s ease;
  opacity: 1;
  margin-top: 10px;
}


.names.containerClose {
  animation: names-closed 1s ease;
  height: 0px;
  opacity: 0;
  z-index: -1;
  margin-top: calc(-1 * var(--vertical-spacing-normal));
}

.input {
  border: none;
  background-color: #f3f6ff;
  width: 100%;
  font-size: var(--font-szie-forms);
  padding: var(--padding-normal);
  border-radius: var(--border-radius-forms);
}

.categories {
  width: 100%;
  width: 100%;
  display: flex;
  gap: var(--vertical-spacing-small);
  flex-direction: column
}

.st_la_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: var(--vertical-spacing-small);
}

.standart, .latina {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.input:focus, .select:focus {
  outline: none;
}

.club:hover {
  cursor: pointer;
}

.gray {
  color: rgba(0, 0, 0, 0.5);
}

.gray > option {
  color: black
}

.button {
  border: none;
  background-color: var(--accent-color);
  width: 100%;
  font-size: var(--font-szie-forms);
  padding: var(--padding-normal);
  border-radius: var(--border-radius-forms);
  transition: 0.5s ease-in-out;
  color: white;
  font-weight: bold;
}

.button:not(.disabled):hover {
  cursor: pointer;
  background-color: #011a5d;
  transition: 0.5s ease-in-out;
}

.button:not(.disabled):active {
  background-color: #011a5d;
  transition: 0.05s ease-in-out;
  transform: scale(0.95, 0.95);
}

.disabled {
  background-color: var(--unselected-input-outline-color);
  transition: 0.5s ease-in-out;
}

.disabled:hover {
  cursor: default;
}

.persData {
  display: flex;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  justify-content: center;
}

.persData > input {
  display: none;
}

.persData > label:hover {
    cursor: pointer;
}

.persData > label {
  position: relative;
  user-select: none;
}

.persData > label::before {
  content: "";
  width: 16px;
  height: 16px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0px;
  left: -30px;
  background-color: white;
  border: 2px solid #011a5d; /* Цвет рамки (темно-синий) */
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s;
}

.persData > label::after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  left: -23px;
  top: 2px;
  left: -23px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.persData > input:checked + label::before {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}

.persData > input:checked + label::after {
  display: block;
}

.message {
  color: red;
}

.good {
  color: green;
}

.note {
  font-weight: bold;
}

@media screen and (max-width: 500px) {
  .persData {
    width: 220px;
  }
}

@media screen and (max-width: 400px) {
  .club {
    font-size: 14px;
  }
  h2 {
    font-size: 20px;
  }
  .persData > label {
    font-size: 14px;
    width: 170px;
  }
  .button, .disabled {
    font-size: 14px;
  }
  
}

@keyframes names-closed {
  0% {
    height: v-bind(formElementHeight);
    opacity: 1;
    margin-top: 10px;
  }

  100% {
    height: 0px;
    opacity: 0;
    margin-top: calc(-1 * var(--vertical-spacing-normal));
  }
}

@keyframes names-open {
  0% {
    opacity: 0;
    height: 0px;
    margin-top: calc(-1 * var(--vertical-spacing-normal));
  }

  100% {
    opacity: 1;
    height: v-bind(formElementHeight);
    margin-top: 10px;
  }
}

@keyframes coomon-closed {
  0% {
    height: v-bind(formElementHeight);
    opacity: 1;
    margin-top: var(--vertical-spacing-normal);;
  }

  100% {
    height: 0px;
    opacity: 0;
    margin-top: calc(-1 * var(--vertical-spacing-normal));
  }
}

@keyframes common-open {
  0% {
    opacity: 0;
    height: 0px;
    margin-top: calc(-1 * var(--vertical-spacing-normal));
  }

  100% {
    opacity: 1;
    height: v-bind(formElementHeight);
    margin-top: var(--vertical-spacing-normal);;
  }
}

@keyframes other-club-closed {
  0% {
    height: v-bind(formElementHeight);
    opacity: 1;
    margin-top: var(--vertical-spacing-normal);
  }

  100% {
    height: 0px;
    opacity: 0;
    margin-top: 0;
  }
}

@keyframes other-club-open {
  0% {
    opacity: 0;
    height: 0px;
    margin-top: 0;
  }

  100% {
    opacity: 1;
    height: v-bind(formElementHeight);
    margin-top: var(--vertical-spacing-normal);
  }
}

</style>

<script>
import Input from '@/components/Input.vue';
import CategoryButton from '@/components/CategoryButton.vue';
import { createParticipant } from '@/utils';
import { DEBUT_SOLO, DEBUT_COUPLES, HOBBY_SOLO, HOBBY_COUPLES, DATE_OF_END } from '@/notes';

export default {
  name: 'RegistrationView',
  components: {
    Input,
    CategoryButton
  },
  data() {
    return {
      formElementHeight: null,
      serverDown: false,
      correctRegistration: false,
      isFinish: false,
      intervalId: null,
      selectedCategory: null,
      selectedCategorySt: null,
      selectedCategoryLa: null,
      isCategoryTarget: false,
      isCategoryTarget: false,
      leader1: '',
      leader2: '',
      follower1: '',
      follower2: '',
      club1: '==',
      club2: '==',
      otherClub1: '',
      otherClub2: '',
      otherClub1Triget: false,
      otherClub2Triget: false,
      checked: false,
      isValid: true,
      note: null,
      result: {
        "leader": null,
        "follower": null,
        "contacts": null,
        "club": "",
        "teacher1": null,
        "teacher2": "",
        "category": [],
        "consent": true
      },
      clubs: [
        "СПХФУ",
        "Горный",
        "СПбПУ Петра Великого",
        "СПбГУ",
        "СПбГУПТД",
        "Грация - МГУ",
        "СПбГЭТУ «ЛЭТИ»",
        "БГТУ «ВОЕНМЕХ»",
        "БГТУ «ВОЕНМЕХ»",
        "ИТМО",
        "ПСПбГМУ им. Павлова",
        "СПБГЛТУ им. С.М. Кирова",
      ],
      changeCategory: (category) => {
        this.note = null
        this.note = null
        if (["debut-solo", "debut-couples", "hobby-solo", "hobby-couples"].includes(category)) {
          if (["debut-solo", "hobby-solo"].includes(category)) {
            this.isCategoryTarget = true
          }
          if (["debut-solo", "hobby-solo"].includes(category)) {
            this.isCategoryTarget = true
          }
          this.selectedCategorySt = null
          this.selectedCategoryLa = null
          if (this.selectedCategory === category) {
            this.selectedCategory = null
          } else {
            this.selectedCategory = category
            switch(category) {
              case "debut-solo":
                this.note = DEBUT_SOLO
                break
              case "debut-couples":
                this.note = DEBUT_COUPLES
                break
              case "hobby-solo":
                this.note = HOBBY_SOLO
                break
              case "hobby-couples":
                this.note = HOBBY_COUPLES
                break
            }
            
            switch(category) {
              case "debut-solo":
                this.note = DEBUT_SOLO
                break
              case "debut-couples":
                this.note = DEBUT_COUPLES
                break
              case "hobby-solo":
                this.note = HOBBY_SOLO
                break
              case "hobby-couples":
                this.note = HOBBY_COUPLES
                break
            }
            
          }
        }
        else if (["e-st", "d-st", "cb-st", "open-st"].includes(category)) {
          this.selectedCategory = null
          if (this.selectedCategorySt === category) {
            this.selectedCategorySt = null
          } else {
            this.selectedCategorySt = category
          }
        } else {
          this.selectedCategory = null
          if (this.selectedCategoryLa === category) {
            this.selectedCategoryLa = null
          } else {
            this.selectedCategoryLa = category
          }
        }
      },
      clearAll() {
        this.result =  {
                "leader": null,
                "follower": null,
                "contacts": null,
                "club": "",
                "teacher1": null,
                "teacher2": "",
                "category": [],
                "consent": true
              }
              this.selectedCategory = null
              this.selectedCategorySt= null
              this.selectedCategoryLa= null
              this.isCategoryTarget= false
              this.leader1= ''
              this.leader2= ''
              this.follower1= ''
              this.follower2= ''
              this.checked= false
              this.isValid= true
              this.club1= '=='
              this.club2= '=='
              this.otherClub1= ''
              this.otherClub2= ''
              this.otherClub1Triget = false
              this.otherClub2Triget = false
      },
      send() {
        this.isValid = true
        if (!this.leader1 || !this.leader2) {
          this.isValid = false
        }

        this.result.leader = `${this.leader1} ${this.leader2}`

        if (this.selectedCategory === 'debut-solo' || this.selectedCategory === 'hobby-solo') {
          this.result.follower = ""
        } else {
          this.result.follower =  `${this.follower1} ${this.follower2}`
          if (!this.follower1 || !this.follower2) {
            this.isValid = false
          }
        }

        if (this.selectedCategory) {
          this.result.category = [this.selectedCategory]
        } else {
          const categories = []
          if (this.selectedCategorySt) {
            categories.push(this.selectedCategorySt)
          }
          if (this.selectedCategoryLa) {
            categories.push(this.selectedCategoryLa)
          }
          this.result.category = categories
        }

        if (this.result.category.length === 0) {
          this.isValid = false
        }

        if (!this.result.teacher1 || !this.result.contacts) {
          this.isValid = false
        }

        if (this.club1 === "==" || this.club1 === "") {
          this.isValid = false
        }

        this.result.club = (this.club1 === "Другое" ? this.otherClub1 : this.club1)
        if (this.club2 !== '==') {
          const clubres2 = (this.club2 === "Другое" ? this.otherClub2 : this.club2)
          if (clubres2 !== this.result.club) {
            this.result.club += ", " + clubres2
          }
        }

        const send = {...this.result}

        if (this.isValid && !this.correctRegistration) {
          createParticipant(send)
          .then((res) => {
            console.log(res)
            if (res.status == 200) {
              this.correctRegistration = true
              this.clearAll()
            } else {
              this.serverDown = true
            }
          })
          .catch((err) => {
            console.log(err)
            this.serverDown = true
          })
        }
        console.log(this.result)
      }
    }
  },
  watch: {
    club1(newValue, oldValue) {
      if (newValue !== oldValue && newValue == "Другое") {
        this.otherClub1Triget = true;
      }
    },
    club2(newValue, oldValue) {
      if (newValue !== oldValue && newValue == "Другое") {
        this.otherClub2Triget = true;
      }
    }
  },
  mounted () {
    this.formElementHeight = `${this.$refs.baseFormElement.offsetHeight}px`;
    console.log( this.formElementHeight);
    if (DATE_OF_END < new Date()) {
        this.isFinish = true
    }
    this.intervalId = setInterval(() => {
      if (DATE_OF_END < new Date()) {
        this.isFinish = true
      }
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  }
}
</script>

