<template>
  <div class="InpContainer full" >
      <input class="input" type="text" required :value="modelValue" @input="updateValue($event.target.value)" >
      <span class="floating-label">{{ placeholder }}</span>
  </div>
  
</template>

<style scoped>
.InpContainer {
  position: relative;
}

.full {
  width: 100%;
  width: 100%;
}

.input {
border: none;
background-color: #f3f6ff;
width: 100%;
font-size: var(--font-szie-forms);
padding: var(--padding-normal);
border-radius: var(--border-radius-forms);
}

.input:focus ~ .floating-label, .input:not(:focus):valid ~ .floating-label {
top: 6px;
font-size: 11px;
opacity: 1;
transform: translate(0, 0);
}

.floating-label {
position: absolute;
font-size: 16px;
color: rgba(0, 0, 0, 0.5);
pointer-events: none;
left: var(--padding-normal);
top: 50%;
transform: translate(0, -50%);
transition: 0.2s ease all;
}

@media screen and (max-width: 400px) {
.floating-label, .input {
  font-size: 14px;
}
}
</style>

<script>
export default {
  name: 'Input',
  props: {
      placeholder: String,
      isFull: Boolean,
      modelValue: String,
  },
  methods: {
  updateValue(value) {
    this.$emit('update:modelValue', value);  // Передаем обновленное значение обратно родителю
  }
}
}
</script>
