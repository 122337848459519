<template>
    <div class="container">
        <p class="names">
          <span>{{ item.leader }}</span>
          <span>{{ item.follower }}</span>
        </p>
        <p class="">{{ item.club }}</p>
        <p class="teacher">{{ item.teacher }}</p>
    </div>
    <hr />
</template>

<style scoped>

.container {
    display: flex;
    justify-content: space-between;
}

p {
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
    min-width: 200px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

span {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.names {
  min-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 900px) {
  p {
    font-size: 13px;
    min-width: 100px;
  }
  .names {
    min-width: 150px;
  }
  .teacher {
    max-width: 150px;
  }
}

@media screen and (max-width: 500px) {
  p {
    font-size: 10px;
    min-width: 75px;
  }
  .names {
    min-width: 100px;
  }
  .teacher {
    max-width: 100px;
  }
}

</style>

<script>
export default {
  name: 'Registered',
  props: {
    item: Object
  }
}
</script>