<template>
  <button
    :class="'customInp' + (isLong ? ' longCategory' : ' category') + (selectedCategory === name ? ' selected' : '')"
    @click="() => changeCategory(name)">
    <span>{{ fullName }}</span>
    <span class="dot"><span class="dot-center"></span></span>
  </button>
</template>

<style scoped>
.customInp {
  border: 1px solid var(--unselected-input-outline-color);
  font-size: 18px;
  font-weight: bold;
  padding: var(--padding-normal);
  border-radius: var(--border-radius-forms);
  color: var(--gray-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s ease all;
}

.customInp > span {
  text-align: left;
}
.dot {
  min-width: var(--font-size-forms);
  min-height: var(--font-size-forms);
  border-radius: 50%;
  background-color: var(--unselected-input-outline-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected .dot {
  background-color: transparent;
  border: 2px solid var(--accent-color);
}

.dot-center {
  width: 0;
  height: 0;
}

.selected .dot-center {
  border-radius: 50%;
  width: calc(var(--font-size-forms) * 0.5);
  height: calc(var(--font-size-forms) * 0.5);
  background-color: var(--accent-color);
}


.longCategory {
  width: 100%;
  background-color: white
}

.category {
  width: 100%;
  background-color: white;
}

.category:hover,
.longCategory:hover {
  cursor: pointer;
}

.selected {
  background-color: var(--accent-background-color);
  border-color: var(--accent-color);
  color: black;
}

@media screen and (max-width: 550px) {
  span {
    max-width: 105px;
  }
  .longCategory > span {
    max-width: 200px;
  }
}

@media screen and (max-width: 500px) {
  .customInp {
    font-size: 14px;
  }
}
</style>

<script>
export default {
  name: 'CategoryBtn',
  props: {
    isLong: Boolean,
    name: String,
    fullName: String,
    changeCategory: Function,
    selectedCategory: String,
  }
}
</script>