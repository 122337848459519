<template>
    <div class="container">
        <Message msg="Положение к турниру"/>
    </div>
</template>

<script>
import Message from '@/components/Message.vue';

export default {
  name: 'Results',
  components: {
    Message
  }
}
</script>