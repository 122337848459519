<template>
    <div class="container">
        <img class="logo" src="../assets/kronbars-96.png" alt="BarsLogo.png" >
        <div class="title">
            <h1>Открытый кубок ИТМО</h1>
            <h3>Дата: 8 декабря</h3>
        </div>
        <div class="timer"><Timer /></div>
    </div>
</template>

<style scoped>
    .container {
        width: fit-content;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        padding-top: 20px;
    }

    .logo {
        width: 120px;
        margin: 0px 85px;
    }

    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h1 {
        font-size: 40px;
        color: #011a5d;
    }
    h3 {
        margin-top: 20px;
        font-size: 18px;
        color: #011a5d;
    }

    .timer {
        max-width: 305px;
    }

    @media screen and (max-width: 900px) {
        .logo {
            display: none;
        }
        h1 {
            font-size: 30px;
        }
        .container {
            flex-direction: column;
            gap: 0px;
        }
    }

    @media screen and (max-width: 400px) {
        h1 {
            width: 250px;
            font-size: 25px;
        }

    }
</style>

<script>
import Timer from './Timer.vue';

export default {
  name: 'Logo',
  components: {
    Timer
  },
  props: {

  }
}
</script>